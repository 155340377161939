import React from "react";
import List from "./list";
import Placeholder from "./Placeholder";
import OuryJalloh from "../pages/singleBiographies/OuryJalloh";
import Burak from "../pages/singleBiographies/BurakBektas";

export const IndexPage = () => {
  const contentByDomain = {
    "hanau.telltheirstories.de": <List searchInput={"hanau"} hideSearchButton />,
    "ouryjalloh.telltheirstories.de": <OuryJalloh />,
    "burak.telltheirstories.de": <Burak />,
    "fulllist.telltheirstories.de": <List />,
    "telltheirstories.de": <Placeholder />
  }

  if (typeof window !== `undefined`) {
    return (<>{contentByDomain[window.location.hostname] || <Placeholder />}</>)
  } else {
    return <></>
  }
};

export default IndexPage;
